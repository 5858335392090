import React from 'react';
import styles from './App.module.css';

function App() {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1>Software Design & Development</h1>
        <p>Your partner in innovative software solutions</p>
      </header>
      <main className={styles.main}>
        <section>
          <h2>Our Services</h2>
          <ul>
            <li>Custom Web Applications</li>
            <li>Windows Desktop Development</li>
            <li>Database Design</li>
            <li>Consulting & Strategy</li>
          </ul>
        </section>
      </main>
      <footer className={styles.footer}>
        <p>&copy; 2025 Software Design & Development. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
